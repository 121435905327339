/* You can add global styles to this file, and also import other style files */

#launcher-frame {
  min-width: 90px !important;
  max-width: 90px !important;
}

.ant-divider-horizontal.ant-divider-with-text {
  color: rgb(0 0 0 / 45%);
  font-size: small;
}
